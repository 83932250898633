import { useRouter } from 'next/router'
import { useEffect } from 'react'
import { ReactNode } from 'react'
import getConfig from 'next/config'

type Props = {
  readonly children: ReactNode
  readonly login: string
}

declare global {
  interface Window {
    dataLayer: any[]
  }
}

const pageView = (url: string, login: string) => {
  if (typeof window === 'object') {
    const data = {
      event: 'pageview',
      page: url,
      login: login,
    }
    const { publicRuntimeConfig } = getConfig()
    if (publicRuntimeConfig.BFF_RUNTIME_ENV !== 'local') {
      window.dataLayer.push(data)
    } else {
      /* eslint-disable no-console */
      console.log('GTM Pushed', data)
    }
  }
}

export function GTMProvider({ login, children }: Props) {
  const router = useRouter()

  useEffect(() => pageView(window.location.href, login))
  useEffect(() => {
    router.events.on('routeChangeComplete', url => pageView(url, login))
    return () => {
      router.events.off('routeChangeComplete', url => pageView(url, login))
    }
  }, [router.events, login])

  return <>{children}</>
}
