import Head from 'next/head'
import { CsrfTokenProvider } from '../contexts/csrfToken'
import { GTMProvider } from '../contexts/gtm'
import { GlobalStyle } from '../styles/Globals'

import { AppLayout } from '@layouts/App'

function App({ Component, pageProps }: any) {
  const { csrfToken, login, ...restProps } = pageProps

  return (
    <CsrfTokenProvider csrfToken={csrfToken}>
      <GTMProvider login={login}>
        <Head>
          <meta name="viewport" content="user-scalable=no, viewport-fit=cover" />
        </Head>
        <GlobalStyle />
        <AppLayout>
          <Component {...restProps} />
        </AppLayout>
      </GTMProvider>
    </CsrfTokenProvider>
  )
}

export default App
