import { useContext, createContext, ReactNode } from 'react'

const ctx = createContext('')

type Props = {
  readonly children: ReactNode
  readonly csrfToken: string
}

const { Provider } = ctx

export function CsrfTokenProvider({ csrfToken, children }: Props) {
  return <Provider value={csrfToken}>{children}</Provider>
}

export const useCsrfToken = () => {
  return useContext(ctx)
}
