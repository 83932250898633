import { createGlobalStyle } from 'styled-components'
import resetercss from 'reseter.css/src/styled-components/ts/reseter'
import 'material-icons/iconfont/material-icons.css'

export const GlobalStyle = createGlobalStyle`
  ${resetercss}
  body {
    font-family:
      "Helvetica Neue",
      Arial,
      "Hiragino Kaku Gothic ProN",
      "Hiragino Sans",
      Meiryo,
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-feature-settings: 'palt' on;
    letter-spacing: 0.04em;
  }
`
