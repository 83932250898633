import { ValueOf } from '@components/types'

export const Colors = {
  brand: '#0065a9',
  white: '#fff',
  gray: '#212121',
  success: '#4caf50',
  info: '#616161',
  error: '#dc3545',
  backgroundBrand: '#0065a9',
  backgroundGray: '#eee',
  backgroundWhite: '#fff',
  inputBorder: '#212121',
  inputFocus: '#0065a9',
  inputError: '#dc3545',
  text: '#191919',
  textWhite: '#fff',
  textLink: '#0065a9',
  textError: '#dc3545',
  placeholder: '#bdbdbd',
  buttonText: '#fff',
  button: 'linear-gradient(to right, #4aa8b4 0%, #266bdb 100%)',
  buttonHover: 'linear-gradient(to right, #308f9c 0%, #0e53c1 100%)',
  buttonFocus: 'linear-gradient(to right, #308f9c 0%, #0e53c1 100%)',
  buttonFocusOutline: '#0065a9',
  buttonActive: 'linear-gradient(to right, #357982 0%, #0c48a8 100%)',
  buttonActiveText: '#e5e5e5',
  buttonDisabled: 'linear-gradient(to right, #a7ccd1 0%, #98b4e1 100%)',
  buttonCancelText: '#757575',
  buttonCancelBorder: '#757575',
  buttonCancel: '#f0f0f0',
  buttonCancelHover: '#d6d6d6',
  buttonCancelFocus: '#d6d6d6',
  buttonCancelActive: '#bdbdbd',
  buttonCancelDisabled: '#f0f0f0',
} as const

export type ColorTypeKeys = keyof typeof Colors
export type ColorTypeValues = ValueOf<typeof Colors>
